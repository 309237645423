import { MixedSchema, ValidationError } from "yup";
import type { IItem } from "~/entities/item";
import {
  handleFieldValidationErrors,
  type IField,
  transformRulesToSchema,
} from "~/entities/field";
import type { ItemValidationErrors } from "./interfaces";

export function validateRefactoredItem(
  item: IItem,
  fields: IField[],
): ItemValidationErrors {
  const errors: ItemValidationErrors = {};
  const itemSchemaShape: Record<string, MixedSchema> = {};

  for (const field of fields) {
    itemSchemaShape[field.name] = transformRulesToSchema(field);
  }

  for (const fieldKey in itemSchemaShape) {
    try {
      const fieldSchema = itemSchemaShape[fieldKey];
      fieldSchema.validateSync(item.getDataProperty(fieldKey));

      if (fieldKey in errors) delete errors[fieldKey];
    } catch (error) {
      if (error instanceof ValidationError) {
        const currentField = fields.find((field) => field.name === fieldKey);

        errors[fieldKey] = handleFieldValidationErrors(currentField as IField, error);
      }
    }
  }

  return errors;
}
